<template>
  <v-app>
    <pages-core-app-disabled />
    <pages-core-user-selector />

    <progress-bar-circular />
    <dashboard-core-app-bar v-model="expandOnHover" />
    <dashboard-core-app-refresh />
    <dashboard-core-drawer :expand-on-hover.sync="expandOnHover" />
    <dashboard-core-view />
  </v-app>
</template>

<script>
import Vue from "vue";

export default {
  name: "baseIndex",

  components: {
    ProgressBarCircular: () => import("@/components/ui/ProgressBarCircular"),
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    DashboardCoreAppRefresh: () => import("./components/core/AppRefresh"),
    DashboardCoreDrawer: () => import("./components/core/Drawer"),
    PagesCoreAppDisabled: () => import("./components/core/AppDisabled"),
    PagesCoreUserSelector: () => import("./components/core/UserSelector"),
    DashboardCoreView: () => import("./components/core/View"),
  },

  created() {
    this.$vuetify.theme.dark = true;
  },

  beforeDestroy() {
    this.$vuetify.theme.dark = true;
  },
  data: () => ({
    expandOnHover: false,
  }),
};
</script>
